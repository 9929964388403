import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './navbar.css';
import logo from "./images/logo.png";
import homeicon from "./images/homeicon.png";
import producticon from "./images/producticon.png";
import aboutus from "./images/aboutusicon.png";
import contacticon from "./images/contacticon.png";
import face from "./images/face.png";
import ins from "./images/ins.png";
import yo from "./images/yo.png";
import twi from "./images/twi.png";
import enquiryimg from "./images/enquiry.png";

const Navbar = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [productsOpen, setProductsOpen] = useState(false);
    const [currentCategory, setCurrentCategory] = useState('');
    const [subCategory, setSubCategory] = useState('');
    const navigate = useNavigate();
    const navbarRef = useRef(null);

    const handleToggle = () => {
        setMenuOpen(!menuOpen);
    };

    const handleCloseMenu = () => {
        setMenuOpen(false);
        setProductsOpen(false);
        setCurrentCategory('');
        setSubCategory('');
    };

    const handleProductsClick = () => {
        setProductsOpen(!productsOpen);
        if (!productsOpen) {
            setCurrentCategory('');
            setSubCategory('');
        }
    };

    const handleCategoryClick = (category) => {
        setCurrentCategory(category);
        setSubCategory('');
    };

    const handleSubCategoryClick = (subcategory) => {
        setSubCategory(subcategory);
    };

    const renderProducts = () => {
        if (subCategory) return null;

        switch (currentCategory) {
            case 'Baler':
                return (
                    <div>
                        <Link to="/bproducts"><h3 className="nav-itemvikah">Baler</h3></Link>
                        <div onClick={() => handleSubCategoryClick('Tyre Scrap Balers')}>
                            <div className="nav-itemvikah">Tyre Scrap Baler</div>
                        </div>
                        <Link to="/Plasticbalers" onClick={handleCloseMenu}><div className="nav-itemvikah">Plastic Balers</div></Link>
                        <Link to="/cbpsb" onClick={handleCloseMenu}><div className="nav-itemvikah">Card Board Balers</div></Link>
                        <Link to="/metalbalers" onClick={handleCloseMenu}><div className="nav-itemvikah">Metal Balers</div></Link>
                    </div>
                );
            case 'Shredder':
                return (
                    <div>
                        <Link to="/shredderproducts"><h3 className="nav-itemvikah">Shredder</h3></Link>
                        <div onClick={() => handleSubCategoryClick('Tyre Shredder')}>
                            <div className="nav-itemvikah">Tyre Shredder</div>
                        </div>
                        <Link to="/metalshredder" onClick={handleCloseMenu}><div className="nav-itemvikah">Metal Shredders</div></Link>
                        <Link to="/plasticshredder" onClick={handleCloseMenu}><div className="nav-itemvikah">Plastic Shredder</div></Link>
                        <Link to="/paperandcardboardshredder" onClick={handleCloseMenu}><div className="nav-itemvikah">Paper Shredder</div></Link>
                    </div>
                );
            case 'Tyre Cutting':
                return (
                    <div>
                        <Link to="/Tyre cutting"><h3 className="nav-itemvikah">Tyre Cutter</h3></Link>

                        <Link to="/Tcpcr100" onClick={handleCloseMenu}><div className="nav-itemvikah">TCPCR100</div></Link>
                        <Link to="/Tctb100" onClick={handleCloseMenu}><div className="nav-itemvikah">TCTB100</div></Link>
                        <Link to="/Tcotr100" onClick={handleCloseMenu}><div className="nav-itemvikah">TCOTR100</div></Link>
                    </div>
                );
            case 'Tyre Folding':
                return (
                    <div>
                        <Link to="/Tyre Folding"><h3 className="nav-itemvikah">Tyre Folding</h3></Link>
                        <Link to="/Tfpcr100" onClick={handleCloseMenu}><div className="nav-itemvikah">TFPCR100</div></Link>
                        <Link to="/Tftbr100" onClick={handleCloseMenu}><div className="nav-itemvikah">TFTBR100</div></Link>
                    </div>
                );
            default:
                return (
                    <div>
                        <div className="nav-itemvikah" onClick={() => handleCategoryClick('Baler')}>Baler</div>
                        <div className="nav-itemvikah" onClick={() => handleCategoryClick('Shredder')}>Shredder</div>
                        <div className="nav-itemvikah" onClick={() => handleCategoryClick('Tyre Cutting')}>Tyre Cutting</div>
                        <div className="nav-itemvikah" onClick={() => handleCategoryClick('Tyre Folding')}>Tyre Folding</div>
                    </div>
                );
        }
    };

    const renderSubSubProducts = () => {
        if (!subCategory) return null;
    
        if (subCategory === 'Tyre Scrap Balers') {
            return (
                <div>
                    <Link to="/Baler"><h4 className="nav-itemvikah">Tyre Scrap Baler</h4></Link>
                    <Link to="/Blt150" onClick={handleCloseMenu}><div className="nav-itemvikah">BLT150</div></Link>
                    <Link to="/Blt200" onClick={handleCloseMenu}><div className="nav-itemvikah">BLT200</div></Link>
                    <Link to="/Blt250" onClick={handleCloseMenu}><div className="nav-itemvikah">BLT250</div></Link>
                </div>
            );
        }
    
        if (subCategory === 'Tyre Shredder') {
            return (
                <div>
                    <Link to="/tyreshredder"><h4 className="nav-itemvikah">Tyre Shredder</h4></Link>
                    <Link to="/Sht2000" onClick={handleCloseMenu}><div className="nav-itemvikah">SHT6000</div></Link>
                    <Link to="/Sht4000" onClick={handleCloseMenu}><div className="nav-itemvikah">SHT8000</div></Link>
                    <Link to="/Sht8000" onClick={handleCloseMenu}><div className="nav-itemvikah">SHT12000</div></Link>
                </div>
            );
        }
        
        return null;
    };

    const [searchTerm, setSearchTerm] = useState('');
   
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            const lowerCaseSearchTerm = searchTerm.toLowerCase();
            const searchMapping = {
                'baler': '/Baler',
                'blt150': '/Blt150',
                'shredder': '/Shredderproducts',
                'sht2000': '/Sht2000',
                'tyre cutting': '/Tyre cutting',
                'tcpcr100': '/Tcpcr100',
                'tctb100': '/Tctb100',
                'tcotr100': '/Tcotr100',
                'tyre folding': '/Tyre Folding',
                'tfpcr100': '/Tfpcr100',
                'tftbr100': '/Tftbr100',
                'blt250':'/Blt250',
                'blt200':'/Blt200',
                'sht4000':"/Sht4000",
                'sht8000':'/Sht8000',
                'about us':'/AboutUsPage',
                'trade fair':'/Trade',
                'enquiry us':'/Login',
                'contact us':'/contact',
                'balers':'/Baler',
                'company':"/AboutUsPage",
                'home':"/",
                'tyre cutter':"/Tyre cutting",
                'metal balers':"/metalbalers"
            };

            const matchedRoute = Object.keys(searchMapping).find(key => key.includes(lowerCaseSearchTerm));

            if (matchedRoute) {
                navigate(searchMapping[matchedRoute]);
            } else {
                navigate('/');
                window.alert('Please enter correct input');
            }
        }
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (navbarRef.current && !navbarRef.current.contains(event.target)) {
                handleCloseMenu();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <>
            <div className='searchcontainer'>
                <label className="home-inner">
                    <input
                        type="text"
                        className="text-input"
                        placeholder='Search for products'
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        onKeyDown={handleKeyDown}
                    />
                </label>
                <div className="icons-container">
                    <a href="https://www.youtube.com/@vikahecotech">
                        <img alt="YouTube" src={yo} />
                    </a>
                    <a href="https://www.instagram.com/vikahecotech/">
                        <img alt="Instagram" src={ins} />
                    </a>
                    <a href="https://x.com/vikahecotech">
                        <img alt="Twitter" src={twi} />
                    </a>
                    <a href="https://www.facebook.com/share/1Mxsd16XWYMsvCyi/?mibextid=qi2Omg">
                        <img alt="Facebook" src={face} />
                    </a>
                </div>
            </div>

            <img className="companylogo" alt="Company Logo" src={logo} />
            <div className={`navbarvikah ${menuOpen ? 'active' : ''}`} ref={navbarRef}>
                <button className="menu-toggle" onClick={handleToggle}>
                    <span></span>
                    <span></span>
                    <span></span>
                </button>
                <div className={`nav-itemsvikah ${menuOpen ? 'active' : ''}`}>
                    <Link to="/" onClick={handleCloseMenu}>
                        <div className="nav-itemvikah">
                            <div className="icon"><img src={homeicon} alt="Home Icon" /></div>
                            <div className="navtextvikah">Home</div>
                        </div>
                    </Link>
                    <Link to="/AboutUsPage" onClick={handleCloseMenu}>
                        <div className="nav-itemvikah">
                            <div className="icon"><img src={aboutus} alt="About Us Icon" /></div>
                            <div className="navtextvikah">About Us</div>
                        </div>
                    </Link>
                    <div className="nav-itemvikah">
                        <div className="icon">
                            <img src={producticon} alt="Products Icon" />
                        </div>
                        <div className="navtextproductvikah" onClick={handleProductsClick}>Products</div>
                        {productsOpen && (
                            <div className={`products-container ${productsOpen ? 'active' : ''}`}>
                                {renderProducts()}
                                {renderSubSubProducts()}
                            </div>
                        )}
                    </div>

                    <Link to="/Login" onClick={handleCloseMenu}>
                        <div className="nav-itemvikah">
                            <div className="icon"><img src={enquiryimg} alt="Enquiry Icon" /></div>
                            <div className="navtextvikah">Enquiry Us</div>
                        </div>
                    </Link>

                    <Link to="/contact" onClick={handleCloseMenu}>
                        <div className="nav-itemvikah">
                            <div className="icon"><img src={contacticon} alt="Contact Us Icon" /></div>
                            <div className="navtextvikah">Contact Us</div>
                        </div>
                    </Link>
                </div>
            </div>
        </>
    );
};

export default Navbar;
