import React, { useState } from 'react';
import './ChatWidget.css'; 
import whatsapp from "./images/whatsapp_icon.jpg";

const ChatWidget = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [message, setMessage] = useState('');

    const toggleChatPopup = () => {
        setIsOpen(!isOpen);
    };

    const sendMessage = () => {
        const whatsappNumber = '918886789356'; 
        if (message.trim() !== '') {
            const whatsappLink = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(message)}`;

            // Debugging: Check the generated link
            console.log(whatsappLink);

            // Open WhatsApp link in a new tab
            window.open(whatsappLink, '_blank');

            // Provide a fallback to WhatsApp Web if the app isn't opened
            setTimeout(() => {
                if (!document.hasFocus()) {
                    window.location.href = `https://web.whatsapp.com/send?phone=${whatsappNumber}&text=${encodeURIComponent(message)}`;
                }
            }, 2000);

            setMessage('');
        }
    };

    return (
        <div>
            {/* Chat Icon/Button */}
            <div className="chat-icon" onClick={toggleChatPopup}>
                <img className='chat-icon' src={whatsapp} alt="Chat Icon"/>
            </div>

            {/* Chat Popup */}
            {isOpen && (
                <div className="chat-popup">
                    <div className="chat-header">
                        <span>Hi, how can I help?</span>
                        <span className="close-btn" onClick={toggleChatPopup}>&times;</span>
                    </div>
                    <div className="chat-body">
                        <input
                            type="text"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            className="chat-input"
                            placeholder="Type your message..."
                        />
                        <button className="send-btn" onClick={sendMessage}>Send</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ChatWidget;
