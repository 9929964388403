import React, { useState,useEffect} from "react";
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import "./tyrecutting.css"
import { Carousel } from "react-responsive-carousel";
import { Link } from "react-router-dom";
import tyrecimage from "./images/tyrecutting1.jpg"
import tyrecimage2 from "./images/tyrecutting2.webp"
import tyrecimage3 from "./images/tyrecutting3.jpg"
import enquiryimg from "./images/enquiry.png"

function Tyrecutting() {
    const [activeSection, setActiveSection] = useState('tcpcr100');
 
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);



        return (
            <>

                
            



                     <div className="carousel-container-balerurl">
                        <Carousel
                            showArrows={true}
                            autoPlay={true}
                            infiniteLoop={true}
                            showThumbs={false}
                            showStatus={false}
                            showIndicators={true}
                            interval={1500}
                        >
                            <div>
                                <img   src={tyrecimage} alt="First Slide" className="carousel-image"/>
                                <p className="legend">Tyre Cutting Machine</p>
                            </div>
                            <div>
                                <img src={tyrecimage2} alt="Second Slide" className="carousel-image" />
                                <p className="legend">Tyre Cutting Machine</p>
                            </div>
                            <div>
                                <img src={tyrecimage} alt="Third Slide" className="carousel-image"/>
                                <p className="legend">Tyre Cutting Machine</p>
                            </div>
                        </Carousel>
                    </div>


                    <div className="heading-tyrescrap1"> Tyre Cutting Machine</div>

                    <div className="htop"><div className="heading-tyrescrap">Model Numbers </div> </div>
                    <div className="headingtyrescraps">
                <b
                    className={`headingtyrescrap ${activeSection === 'tcpcr100' ? 'active' : ''}`}
                    onClick={() => setActiveSection('tcpcr100')}
                >
                    
                    TCPCR 100
                </b>
                <b
                    className={`headingtyrescrap ${activeSection === 'tctb100' ? 'active' : ''}`}
                    onClick={() => setActiveSection('tctb100')}
                >
                   
                    TCTB 100
                </b>
                <b
                    className={`headingtyrescrap ${activeSection === 'tcotr100' ? 'active' : ''}`}
                    onClick={() => setActiveSection('tcotr100')}
                >
                  TCOTR 100
                </b>
            </div>

      




                            {activeSection === 'tcpcr100' && (
                                  <div className="flip-card">
                                    <div className="flip-card-inner">
                                      <div className="flip-card-front">
                                        <img  src={tyrecimage} alt="Avatar" className="flipimage" />
                                      </div>
                                      <div className="flip-card-back">
                                        <h1>TCPCR100</h1>
                                        <p><b>Type of Machine:</b>Tyre cutting</p>
                                        <p><b>Size:</b> 1530 mm * 2700 mm * 1200 mm</p>
                                        <p><b>Pressure:</b> 3000 PSI</p>
                                        <p> <b>Power Range:</b> 20 HP,960 RPM, 50HZ, 3 Phase+2phase</p>
                                        <Link to="/Tcpcr100" className="link-button">
                                          Read More
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                )}


             {activeSection === 'tctb100' && (
                                  <div className="flip-card">
                                    <div className="flip-card-inner">
                                      <div className="flip-card-front">
                                        <img  src={tyrecimage2} alt="Avatar" className="flipimage" />
                                      </div>
                                      <div className="flip-card-back">
                                        <h1>TCTB100</h1>
                                        <p><b>Type of Machine:</b>Tyre cutting</p>
                                        <p><b>Size:</b> 1530 mm * 2700 mm * 1200 mm</p>
                                        <p><b>Panel:</b> 415v, 3 phase,50HzI</p>
                                        <p> <b>Power Range:</b> 20 HP,960 RPM, 50HZ, 3 Phase+2phase</p>
                                        <Link to="/Tctb100" className="link-button">
                                          Read More
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                )}



           


                    {activeSection === 'tcotr100' && (
                                  <div className="flip-card">
                                    <div className="flip-card-inner">
                                      <div className="flip-card-front">
                                        <img  src={tyrecimage3} alt="Avatar" className="flipimage" />
                                      </div>
                                      <div className="flip-card-back">
                                        <h1>TCOTR100</h1>
                                        <p><b>Type of Machine:</b>Tyre cutting</p>
                                        <p><b>Size:</b> 1530 mm * 2700 mm * 1200 mm</p>
                                        <p><b>Panel:</b> 415v, 3 phase,50HzI</p>
                                        <p> <b>Power Range:</b> 20 HP,960 RPM, 50HZ, 3 Phase+2phase</p>
                                        <Link to="/Tcotr100" className="link-button">
                                          Read More
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                )}
<div className='enquiry-balerapp-container'>
                    <img className="enq-balerapp" src={enquiryimg} alt="Enquiry"/>
                    <div className='enquiry-balerapp'> 
                    For more information about your Tyre cuttting requirement please enquire us at <a href="mailto:info@vikahecotech@gmail.com"> info@vikahecotech.com</a> 
                    </div>
                    </div>
            </>
        )
    }

export default Tyrecutting