import React, { Component,useEffect,useState } from "react";
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import "./tyrecutting.css"
import { Carousel } from "react-responsive-carousel";
import { Link } from "react-router-dom";
import tyrefoldingimg from "./images/tyre folding1.jpg"
import tyrefoldingimg1 from "./images/tyre folding2.jpg"
import tyrefoldingimg2 from "./images/tyre folding3.jpg"
import enquiryimg from "./images/enquiry.png"



function Tyrefolding() {

    const [activeSection, setActiveSection] = useState('tfpcr100');
 
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


        return (
            <>

                
           



                     <div className="carousel-container-balerurl">
                        <Carousel
                            showArrows={true}
                            autoPlay={true}
                            infiniteLoop={true}
                            showThumbs={false}
                            showStatus={false}
                            showIndicators={true}
                            interval={1500}
                        >
                            <div>
                                <img style={{"objectFit":"cover"}}  src={tyrefoldingimg} alt="First Slide" className="carousel-image"/>
                                <p className="legend">Tyre Folding</p>
                            </div>
                            <div>
                                <img style={{"objectFit":"cover"}}  src={tyrefoldingimg1} alt="Second Slide" className="carousel-image" />
                                <p className="legend">Tyre Folding</p>
                            </div>
                            <div>
                                <img style={{"objectFit":"cover"}}  src={tyrefoldingimg2} alt="Third Slide" className="carousel-image"/>
                                <p className="legend">Tyre Folding</p>
                            </div>
                        </Carousel>
                    </div>
                    <div className="heading-balerurl" style={{marginLeft:"46%"}}>  Tyre Folding</div>
                   <div className="htop-balerurl"> <div className="heading-tyrescrap">Model Numbers </div> </div>
                    
               
     
                    <div  className="headingtyrescraps-tyrefolding">
        <b
          className={`headingtyrescrap ${activeSection === 'tfpcr100' ? 'active' : ''}`}
          onClick={() => setActiveSection('tfpcr100')}
        >
          TFPCR100
        </b>
        <b
          className={`headingtyrescrap ${activeSection === 'tftbr100' ? 'active' : ''}`}
          onClick={() => setActiveSection('tftbr100')}
        >
          TFTBR100
        </b>
      </div>



                        {activeSection === 'tfpcr100' && (
                                  <div className="flip-card">
                                    <div className="flip-card-inner">
                                      <div className="flip-card-front">
                                        <img  src={tyrefoldingimg} alt="Avatar" className="flipimage" />
                                      </div>
                                      <div className="flip-card-back">
                                        <h1>TFPCR100</h1>
                                        <p><b>Type of Machine:</b>Tyre Folding</p>
                                        <p><b>Size:</b> 1530 mm * 2700 mm * 1200 mm</p>
                                        <p><b>Panel:</b> 415v, 3 phase,50HzI</p>
                                        <p> <b>Power Range:</b> 20 HP,960 RPM, 50HZ, 3 Phase+2phase</p>
                                        <Link to="/tfpcr100" className="link-button">
                                          Read More
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                )}


{activeSection === 'tftbr100' && (
                                  <div className="flip-card">
                                    <div className="flip-card-inner">
                                      <div className="flip-card-front">
                                        <img  src={tyrefoldingimg1} alt="Avatar" className="flipimage" />
                                      </div>
                                      <div className="flip-card-back">
                                        <h1>TFTBR100</h1>
                                        <p><b>Type of Machine:</b>Tyre Folding</p>
                                        <p><b>Size:</b> 1530 mm * 2700 mm * 1200 mm</p>
                                        <p><b>Panel:</b> 415v, 3 phase,50HzI</p>
                                        <p> <b>Power Range:</b> 20 HP,960 RPM, 50HZ, 3 Phase+2phase</p>
                                        <Link to="/tftbr100" className="link-button">
                                          Read More
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                )}
<div className='enquiry-balerapp-container'>
                    <img className="enq-balerapp" src={enquiryimg} alt="Enquiry"/>
                    <div className='enquiry-balerapp'> 
                    For more information about your Folding requirement please enquire us at <a href="mailto:info@vikahecotech@gmail.com"> info@vikahecotech.com</a> 
                    </div>
                    </div>



        
  



 







            </>
        )
    }

export default Tyrefolding